const utils = {

  /**
   * 获取Token
   * @returns 
   */
  getUrlToken () {
    let query = window.location.href ? window.location.href.split("?")[1].split("&") : []
    let req = {}
    query.forEach(ele => {
      req[ele.split("=")[0]] = ele.split("=")[1]
      localStorage.setItem(ele.split("=")[0], ele.split("=")[1])
    });
    return req
  }

}

export default utils