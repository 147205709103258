import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'goods',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('../views/goods/index'),
    meta: {
      title: '商品列表',
    }
    // redirect: {
    //   name: 'goodsDetail',
    //   params: { id: '1472747998927654919' }
    // },
  },
  {
    name: 'goodsDetail',
    path: '/goods/:id',
    component: () => import('../views/goods/detail'),
    meta: {
      title: '商品详情',
    }
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('../views/cart'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('../views/order/index'),
    meta: {
      title: '订单列表',
    }
  },
  {
    name: 'orderDetail',
    path: '/order/:id',
    component: () => import('../views/order/detail'),
    meta: {
      title: '订单详情',
    }
  },
  {
    name: 'orderConfirm',
    path: '/order/confirm',
    component: () => import('../views/order/confirm'),
    meta: {
      title: '确认订单',
    }
  },
  {
    name: 'payment',
    path: '/payment/:orderId',
    component: () => import('../views/payment'),
    meta: {
      title: '支付确认',
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
