<template>
  <div id="app">
    <router-view />
    <div style="height: 5rem;"></div>
  </div>
</template>

<style>
body {
  background-color: #efefef;
  min-height: 100vh;
}
</style>