import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

// 获取Token
import utils from './common/utils'

if (!localStorage.getItem('token')) {
  utils.getUrlToken()
}
console.log("token:", localStorage.getItem('token'))

// 路由
import router from './router'

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

app.use(router)

// axios
import axios from 'axios'
import vueAxios from 'vue-axios'
app.use(vueAxios, axios)

const baseURL = {
  development: 'http://localhost:8080',
  production: ''
}
// axios.defaults.withCredentials = true
axios.defaults.timeout = 6000
axios.defaults.baseURL = baseURL[process.env.NODE_ENV]
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
axios.defaults.headers.post['Content-Type'] = 'application/json'

// Vant组件
import {
  CellGroup, Cell,
  Field,
  CheckboxGroup, Checkbox,
  List,
  Card,
  Stepper,
  SubmitBar,
  ActionBar, ActionBarIcon, ActionBarButton,
  Swipe, SwipeItem,
  Divider,
  Tag,
  Icon,
  Button,
  Row, Col,
  Sticky,
  Empty,
  SwipeCell,
  CountDown,
  RadioGroup, Radio,
  AddressEdit,
  Popup, Picker,
} from 'vant'
app.use(CellGroup).use(Cell)
.use(Field)
.use(CheckboxGroup).use(Checkbox)
.use(List)
.use(Card)
.use(Stepper)
.use(SubmitBar)
.use(ActionBar).use(ActionBarIcon).use(ActionBarButton)
.use(Swipe).use(SwipeItem)
.use(Divider)
.use(Tag)
.use(Icon)
.use(Button)
.use(Row).use(Col)
.use(Sticky)
.use(Empty)
.use(SwipeCell)
.use(CountDown)
.use(Radio)
.use(RadioGroup)
.use(AddressEdit)
.use(Popup)
.use(Picker)

app.mount('#app')